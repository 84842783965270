<script>
    import storeI from "../stores/incertitudesStore";
    let valeurs;
    let idxG;
    let incertitudes = [];
    let nbVal;
    let moy;
    let ecartType;
    let incertType;
    let incertTypeArr;
    let puissanceIncert;
    let incertTypeArrk2;
    let puissanceIncertk2;
    let puissanceIncertkStud95;
    let incertTypeArrkStud95;
    let affichage = false;
    let grandeurs;
    let moyArr;
    let moyArrk2;
    let moyArrk95;
    let k95 = [
        "",
        "",
        12.7,
        4.3,
        3.18,
        2.78,
        2.57,
        2.45,
        2.37,
        2.31,
        2.26,
        2.23,
        2.2,
        2.18,
        2.16,
        2.15,
        2.13,
        2.12,
        2.11,
        2.1,
        2.09, // 20 mesures
    ];

    export { incertitudes };
    export { valeurs };
    export { idxG };
    export { grandeurs };

    let incertitude = {
        nbVal,
        moy,
        ecartType,
        incertType,
        incertTypeArr,
        puissanceIncert,
        incertTypeArrk2,
        puissanceIncertk2,
        puissanceIncertkStud95,
        incertTypeArrkStud95,
        moyArr,
        moyArrk2,
        moyArrk95,
    };

    // calcul du nombre de valeurs
    function nbValCalc(valeurs, idxG) {
        let nbVal = 0;
        if (valeurs) {
            for (let i = 0; i < valeurs.length; i++) {
                if (valeurs[i].idxG == idxG && !valeurs[i].lineThrough) {
                    nbVal++;
                }
            }
            incertitude.nbVal = nbVal;
            incertitudes[idxG] = incertitude;
            storeI.set(incertitudes);
            calcMoyenne(valeurs, idxG, incertitudes);
        }
        return "";
    }

    // calcul de la moyenne
    function calcMoyenne(valeurs, idxG) {
        let sum = 0;
        let moyenne = "";
        if (valeurs) {
            if (incertitude.nbVal !== 0) {
                for (let i = 0; i < valeurs.length; i++) {
                    if (valeurs[i].idxG == idxG && !valeurs[i].lineThrough) {
                        sum += valeurs[i].mesure;
                    }
                    moyenne = sum / incertitudes[idxG].nbVal;
                }

                //mise à jour du store
                incertitudes[idxG].moy = moyenne;
                storeI.set(incertitudes);
                ecartTypeExp(valeurs, idxG);
            }
            if (incertitude.nbVal == 0) {
                //mise à jour du store
                incertitudes[idxG].moy = "";
                storeI.set(incertitudes);
            }
        }
    }

    // fonction de calcul de l'écart type expérimental
    function ecartTypeExp(valeurs, idxG) {
        let sigmaExp;
        let sumQuad = 0.0;
        // somme quadratique à la moyenne
        for (let j = 0; j < valeurs.length; j++) {
            if (valeurs[j].idxG == idxG && !valeurs[j].lineThrough) {
                sumQuad += Math.pow(
                    valeurs[j].mesure - incertitudes[idxG].moy,
                    2
                );
            }
        }

        sigmaExp = Math.sqrt(sumQuad) / (incertitudes[idxG].nbVal - 1);
        // écart type expérimental
        if (incertitudes[idxG].nbVal >= 2) {
            //mise à jour du store
            incertitudes[idxG].ecartType = sigmaExp;
            storeI.set(incertitudes);
        } else {
            //mise à jour du store si l'écart Type pas définit
            incertitudes[idxG].ecartType = "";
            storeI.set(incertitudes);
        }
        incertitudeType(idxG);
    }

    // fonction de calcul de l'incertitude type
    function incertitudeType(idxG) {
        let incertType;
        let incertTypeArr1 = [];
        let incertTypeArr1k2 = [];
        let incertTypeArr1k95Stud = [];
        let moyArr;
        if (
            incertitudes[idxG].ecartType !== "" &&
            incertitudes[idxG].nbVal !== 0
        ) {
            incertType =
                incertitudes[idxG].ecartType /
                Math.sqrt(incertitudes[idxG].nbVal);
        } else {
            incertType = "";
        }
        //mise à jour du store si l'écart Type pas définit
        incertitudes[idxG].incertType = incertType;
        storeI.set(incertitudes);

        // arrondi de l'incertitude
        incertTypeArr1 = arrondi1(incertType); // pas délargissement
        incertTypeArr1k2 = arrondi1(incertType * 2); // élargissement de 2
        incertTypeArr1k95Stud = arrondi1(
            incertType * k95[incertitudes[idxG].nbVal]
        ); // élargissement de 2

        //mise à jour du store
        // k = 1
        incertitudes[idxG].incertTypeArr = incertTypeArr1[0];
        incertitudes[idxG].puissanceIncert = incertTypeArr1[1];
        moyArr = arrondiResultat(
            incertitudes[idxG].moy,
            incertitudes[idxG].puissanceIncert
        );
        incertitudes[idxG].incertTypeArrk2 = incertTypeArr1k2[0];
        incertitudes[idxG].puissanceIncertk2 = incertTypeArr1k2[1];
        moyArrk2 = arrondiResultat(
            incertitudes[idxG].moy,
            incertitudes[idxG].puissanceIncertk2
        );
        incertitudes[idxG].incertTypeArrkStud95 = incertTypeArr1k95Stud[0];
        incertitudes[idxG].puissanceIncertkStud95 = incertTypeArr1k95Stud[1];
        moyArrk95 = arrondiResultat(
            incertitudes[idxG].moy,
            incertitudes[idxG].puissanceIncertkStud95
        );

        storeI.set(incertitudes);
        //Arrondi de la moyenne avec le bon nombre de chiffre significatifs.
        /*       moyArr = arrondiResultat(
            incertitudes[idxG].moy,
            incertitudes[idxG].puissanceIncert
        );
        */
        incertitudes[idxG].moyArr = moyArr;
        incertitudes[idxG].moyArrk2 = moyArrk2;
        incertitudes[idxG].moyArrk95 = moyArrk95;
        // mise à jour du store
        storeI.set(incertitudes);

        // affichage de l'écran de résultats
        affichage = true;
    }

    // fonction arrondi prend un nombre en entreé
    // retourne un tbleau de deux valeurs : l'arrondi et la puissance de dix
    function arrondi1(incertType) {
        let i = 0;
        let incertTypeArr;
        console.log(incertType);
        let ITA1 = [];
        if (incertType != "") {
            if (incertType >= 10) {
                while (incertType >= 10) {
                    incertType = incertType / 10;
                    i++;
                }
            } else if (incertType < 1) {
                while (incertType < 1) {
                    incertType = incertType * 10;
                    i--;
                }
            } else {
                i = 0;
            }
            incertTypeArr = Math.ceil(incertType);
        }
        if (incertTypeArr === 10) {
            incertTypeArr = 1;
            i++;
        }
        console.log(incertTypeArr, i);
        ITA1[0] = incertTypeArr;
        ITA1[1] = i;
        return ITA1;
    }

    // fonction d'arrondi du résultat
    function arrondiResultat(moyToArr, puissanceIncert) {
        let moyArr;
        moyArr = Math.round(moyToArr * Math.pow(10, -puissanceIncert));
        return moyArr;
    }
</script>

<!--lancement des calculs -->
{nbValCalc(valeurs, idxG)}

<!--Affichage des résultats -->
<div class="titre_result bg-blue-200 text-black font-bold">
    Résultats : <br />
</div>

{#if affichage}
    <div class="results border-blue-600 w-48 h-20">
        <div class="res text-">Nombre de valeurs :</div>
        {incertitudes[idxG].nbVal}
        <br />
        <div class="res text-blue-700">Moyenne :</div>

        {#if incertitudes[idxG].moy !== ""}
            <div class="cont-moy">
                {incertitudes[idxG].moy}
                {grandeurs[idxG].symbolU}
            </div>
        {/if}
        <div class="res">Ecart type expérimental :</div>
        {incertitudes[idxG].ecartType}
        <br />
        <div class="res">Incertitude type :</div>
        <!-- {incertitudes[idxG].incertType} <br /> -->
        {#if incertitudes[idxG].incertTypeArr !== undefined}
            {incertitudes[idxG].incertTypeArr}
            {#if incertitudes[idxG].puissanceIncert !== 0}
                &times; 10<sup>{incertitudes[idxG].puissanceIncert}</sup>
            {/if}
            {grandeurs[idxG].symbolU}
        {/if}
        <br />
        <br />
        <!-- Expressions des différents résultats-->
        <div class="res">Expression du résultat :</div>
        <br />
        <hr class="separateur" />
        <!-- Expressions avec k = 1-->
        <div class="description">Intervalle de confiance 68%(k=1)</div>
        {#if incertitudes[idxG].incertTypeArr !== undefined}
            {grandeurs[idxG].symbolG} = ({incertitudes[idxG].moyArr} &plusmn;
            {incertitudes[idxG].incertTypeArr})
            {#if incertitudes[idxG].puissanceIncert !== 0}
                &times; 10<sup>{incertitudes[idxG].puissanceIncert}</sup>
            {/if}
            {grandeurs[idxG].symbolU}
        {/if}
        <hr class="separateur" />

        <!-- Expressions avec k = 2-->
        <div class="description">
            Incertitudes élargies simplifiées <br />
            Intervalle de confiance 95%(k=2)
        </div>
        {#if incertitudes[idxG].incertTypeArrk2 !== undefined}
            {grandeurs[idxG].symbolG} = ({incertitudes[idxG].moyArrk2} &plusmn;
            {incertitudes[idxG].incertTypeArrk2})
            {#if incertitudes[idxG].puissanceIncertk2 !== 0}
                &times; 10<sup>{incertitudes[idxG].puissanceIncertk2}</sup>
            {/if}
            {grandeurs[idxG].symbolU}
        {/if}
        <hr class="separateur" />
        <!-- Expressions avec k = student-->
        <div class="description">
            Incertitudes élargies (Student)<br />
            Intervalle de confiance 95%
        </div>
        {#if incertitudes[idxG].incertTypeArrkStud95 !== undefined}
            {grandeurs[idxG].symbolG} = ({incertitudes[idxG].moyArrk95} &plusmn;
            {incertitudes[idxG].incertTypeArrkStud95})
            {#if incertitudes[idxG].puissanceIncertkStud95 !== 0}
                &times; 10<sup>{incertitudes[idxG].puissanceIncertkStud95}</sup>
            {/if}

            {grandeurs[idxG].symbolU} <br />
        {/if}
        <span class="elargi"> k = {k95[incertitudes[idxG].nbVal]} - 95%</span>
    </div>
{/if}

<style>
    .titre_result {
        text-align: center;
        margin-bottom: 20px;
    }
    .results {
        text-align: left;
        padding-left: 5px;
        font-weight: bold;
    }
    .res {
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        color: #2b6cb0;
        background-color: #edf2f7;
        width: 240px;
    }
    .description {
        font-style: italic;
        font-style: 10px;
        font-weight: normal;
        width: 240px;
    }
    .separateur {
        border: 1px dashed #000;
        width: 240px;
    }
    .elargi {
        font-style: italic;
        font-style: 10px;
        font-weight: normal;
    }
    .cont-moy {
        width: 240px;
    }
</style>
