<script>
  import store from "../stores/dataStore";
  import RenderValues from "./renderValues.svelte";
  import { createEventDispatcher } from "svelte";

  let mesure = [];
  let valeurs;
  let grandeurs;
  let incertitudes;
  const dispatch2 = createEventDispatcher();
  const dispatch5 = createEventDispatcher();
  export { grandeurs };
  export { valeurs };
  export { incertitudes };

  //let selectId;
  let lineThrough = false;
  let idx = 0;
  let idxG;

  let objVal = {
    idxG: idxG, // index de la grandeur
    idx: idx, // index de la valeur
    lineThrough: lineThrough,
    mesure: mesure,
  };

  //fonction pour enregistrer les valeurs
  // avec des indices de valeurs (idx) 0, 1, 2 etc pour chaque valeur
  function setValue() {
    let nbIdxNewG = [];
    idxG = mesure.length - 1;
    if (idxG == undefined) {
      idx = 0;
    } else {
      let nbVals = 0;
      for (let k = 0; k < grandeurs.length; k++) {
        for (let l = 0; l < valeurs.length; l++) {
          if (valeurs[l].idxG == k) {
            nbVals++;
          }
        }
        nbIdxNewG.push(nbVals);
        nbVals = 0;
      }
      idx = nbIdxNewG[idxG];
    }
    nbIdxNewG = [];
    if (mesure) {
      idxG = mesure.length - 1;
      objVal.idxG = idxG;
      objVal.idx = idx;
      objVal.lineThrough = lineThrough;
      objVal.mesure = mesure[idxG];
    }
    store.update((data) => {
      return [...data, objVal];
    });
    mesure = [];
    objVal = {};
    idxG = undefined;
  }

  // fonction de suppression d'une grandeur. idxGSupp indice grandeur à supprimer
  // met 0 dans le tableau de grandeurs pour la supprimer sans décaler les indices
  function supGrandeur(idxGSupp) {
    //grandeurs.splice(idxGSupp, 1);
    grandeurs[idxGSupp] = 0; // mise à zéro de la grandeur supprimée
    // mise à false des onglets actifs
    for (let j = 0; j < grandeurs.length; j++) {
      if (grandeurs[j] !== 0) {
        grandeurs[j].active = false;
      }
    }
    for (let j = 0; j < grandeurs.length; j++) {
      if (grandeurs[j] !== 0 && !grandeurs[j].active) {
        grandeurs[j].active = true;
        break;
      }
    }

    // Recherche d'une grandeur inactive à rendre active
    dispatch2("delGrandeur", grandeurs);
    let longueurTab = valeurs.length;
    for (let i = 0; i < longueurTab; i++) {
      if (valeurs[i].idxG == idxGSupp) {
        valeurs.splice(i, 1);
        i--;
        longueurTab = valeurs.length;
      }
    }
  }
  // transmet le custom event de onglet jusqu'à App
  function changeActiveG2(e) {
    grandeurs = e.detail;
    dispatch5("activeOnglet3", grandeurs);
  }
  // Change l'onglet quand le focus sur l'input de données change
  function changeFocus(idxG) {
    for (let i = 0; i < grandeurs.length; i++) {
      if (grandeurs[i] !== 0) {
        if (i == idxG) {
          grandeurs[i].active = true;
        } else {
          grandeurs[i].active = false;
        }
      }
    }
    dispatch5("activeOnglet3", grandeurs);
  }
</script>

{#if grandeurs}
  {#each grandeurs as grandeur, idxG}
    {#if grandeurs[idxG].visible}
      <div class="mt-4">
        <div
          class="bg-gray-200 rounded-t-md border-gray-400 border-2 pl-4 header"
        >
          <div class=" float-left txtG">
            <strong class="text-blue-700">{grandeur.symbolG}</strong>
            (en <strong class="text-blue-700">{grandeur.symbolU})</strong>
          </div>
          <!-- Bouton agrandir-->
          <div />
          {#if !grandeurs[idxG].active}
            <div class="btn-agrandir">
              <button
                on:click={changeFocus(grandeur.idxG)}
                class="text-black font-bold">&#128470;&#xFE0E;</button
              >
            </div>
          {/if}
          <!--Bouton supprimer-->
          <div class="btn-fermer">
            <button
              on:click={supGrandeur(grandeur.idxG)}
              class="text-black font-bold">&#128473;&#xFE0E;</button
            >
          </div>
        </div>
        <div
          class:reduire={!grandeurs[idxG].active}
          class="border-gray-400 border-2 w-56 mt-0 p-2 text-center cadre-value"
        >
          <form on:submit|preventDefault={setValue}>
            <input
              type="number"
              step="any"
              name="input-number"
              class="border-2 rounded-md border-gray-400 pl-2 bg-white"
              bind:value={mesure[idxG]}
              placeholder="Entrer les valeurs"
              on:focus={() => changeFocus(idxG)}
            />
          </form>
        </div>
        <div>
          <RenderValues
            {valeurs}
            {idxG}
            {grandeurs}
            {incertitudes}
            on:activeOnglet2={changeActiveG2}
          />
        </div>
      </div>
    {/if}
  {/each}
{/if}

<style>
  /* Retire les flèches de inputs */
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Chrome */
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Opéra*/
  input::-o-inner-spin-button,
  input::-o-outer-spin-button {
    -o-appearance: none;
    margin: 0;
  }
  .header {
    z-index: 2;
    height: 30px;
  }
  .txtG {
    width: 140px;
  }

  .btn-fermer {
    margin-right: 10px;
    float: right;
  }
  .reduire {
    display: none;
  }
  .cadre-value {
    z-index: -1;
    margin-top: -2px;
  }
  .btn-agrandir {
    display: inline-block;
    margin-left: 15px;
  }
</style>
