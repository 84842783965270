<script>
  import FormStarter from "./components/formStarter.svelte";
  import GetValue from "./components/getValue.svelte";
  import store from "./stores/dataStore.js";
  import storeI from "./stores/incertitudesStore.js";

  export let name;
  let grandeurs;
  let valeurs = [];
  let incertitudes = [];

  // Store des valeurs
  store.subscribe((data) => {
    valeurs = data;
  });

  // Store des incertitudes calculées
  storeI.subscribe((data) => {
    incertitudes = data;
  });

  function addGrandeur(e) {
    grandeurs = e.detail;
  }
  function removeGrandeur(e) {
    grandeurs = e.detail;
  }
  function changeActiveG3(e) {
    grandeurs = e.detail;
  }
</script>

<main>
  <div class="border-gray-400 rounded-md border-2 w-56 mb-2 bg-blue-300">
    <h1 class="text-xl text-center my-2">{name} !</h1>
  </div>
  <div class="starter">
    <div>
      <FormStarter on:newGrandeur={addGrandeur} />
    </div>
    <div>
      <div>
        <GetValue
          on:delGrandeur={removeGrandeur}
          on:activeOnglet3={changeActiveG3}
          {grandeurs}
          {valeurs}
          {incertitudes}
        />
      </div>
    </div>
  </div>
</main>

<style>
  .starter {
    float: left;
  }
  main {
    padding: 1em;
    max-width: 240px;
    margin-left: 10;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
