<script>
    import { createEventDispatcher } from "svelte";
    let grandeurs;
    export { grandeurs };
    const dispatch3 = createEventDispatcher();

    // changement de couleur de onglets au clic
    function changeOnglet(idxO) {
        for (let i = 0; i < grandeurs.length; i++) {
            if (grandeurs[i] !== 0) {
                if (i == idxO) {
                    grandeurs[i].active = true;
                } else {
                    grandeurs[i].active = false;
                }
            }
        }
        dispatch3("activeOnglet", grandeurs);
    }
</script>

<div class="ongletContainer">
    {#each grandeurs as grandeur, idxO}
        {#if grandeur}
            <div
                class="active onglet border-gray-400 border-t-2 border-l-2 border-r-2 rounded-t-md"
                class:active={grandeurs[idxO].active}
                id={"onglet-" + idxO}
                on:click={() => changeOnglet(idxO)}
            >
                {grandeur.symbolG}
            </div>
        {/if}
    {/each}
</div>

<style>
    .ongletContainer {
        position: absolute;
        top: 27px;
        left: 265px;
        width: 282px;
        height: 32px;
        display: inline-block;
        text-align: left;
    }
    .onglet {
        float: left;
        width: 32px;
        height: 32px;
        text-align: center;
        display: inline;
        cursor: pointer;
    }
    .active {
        background-color: #cbd5e0;
    }
</style>
