<script>
  import { createEventDispatcher } from "svelte";
  let hide = true;
  let symbolG;
  let symbolU;
  let grandeurs = [];
  let idxG = 0;

  const dispatch = createEventDispatcher();

  function CreateVisible() {
    hide = !hide;
  }
  function valid() {
    let nbelementG = 0;
    for (let i = 0; i < grandeurs.length; i++) {
      if (grandeurs[i] !== 0) {
        nbelementG++;
      }
    }

    if (symbolG && symbolU && nbelementG < 8) {
      const grandeur = {
        symbolG,
        symbolU,
        idxG,
        visible: true,
        active: true,
      };
      // on mets tous les onglets antérieurs sur inactif (active = false)
      for (let i = 0; i < grandeurs.length; i++) {
        if (grandeurs[i] !== 0) {
          grandeurs[i].active = false;
        }
      }
      grandeurs = [...grandeurs, grandeur];
      symbolG = "";
      symbolU = "";
      hide = !hide;
      dispatch("newGrandeur", grandeurs);
      idxG++;
    } else {
      alert("Saisie Incorrect");
      symbolG = "";
      symbolU = "";
    }
  }
</script>

<div class="w-56 text-center">
  <div
    class=" bg-gray-200 rounded-md p-6 border-gray-400 border-2"
    class:visible={!hide}
  >
    <button
      on:click={CreateVisible}
      class="bg-blue-600 text-white rounded-md p-2"
      class:visible={!hide}
    >
      Créer une grandeur
    </button>
  </div>
  <div
    class:visible={hide}
    class="border-gray-400 border-2 p-4 rounded-md bg-gray-200"
  >
    <div class="text-left">
      <form on:submit|preventDefault={valid}>
        <label for="symbolG" class="mr-2">Grandeur : </label>

        <input
          type="text"
          name="symbolG"
          class="border-2 rounded-md border-gray-400"
          bind:value={symbolG}
          maxlength="2"
          size="5"
        />
        <br />
        <label for="symbolU" class="margeRU">Unité : </label>

        <input
          type="text"
          name="symbolU"
          class="border-2 rounded-md border-gray-400 mt-2"
          bind:value={symbolU}
          maxlength="7"
          size="5"
        />
        <br />
        <button class="bg-indigo-600 my-4 px-3 py-3 rounded-md text-white">
          Créer
        </button>
      </form>
    </div>
  </div>
</div>

<style>
  .visible {
    float: left;
    display: none;
  }
  .margeRU {
    margin-right: 36px;
  }
</style>
