<script>
  import store from "../stores/dataStore";
  import CalculsResults from "./calculsResults.svelte";
  import Onglets from "./onglets.svelte";
  import { createEventDispatcher } from "svelte";

  let valeurs;
  let idxG;
  let grandeurs;
  let incertitudes;
  export { incertitudes };
  export { grandeurs };
  export { valeurs };
  export { idxG };
  const dispatch4 = createEventDispatcher();

  // fonction pour barrer ou non une valeur
  function selectIdList(idx, lineThrough, idxG) {
    for (let i = 0; i < valeurs.length; i++) {
      if (valeurs[i].idxG == idxG && valeurs[i].idx == idx) {
        valeurs[i].lineThrough = !valeurs[i].lineThrough;
      }
    }
    store.set(valeurs);
  }

  // fonction de suppression d'une valeur dans le store et mise à jour du composant
  // Remet les indices correctement
  function deleteValues() {
    let newIndex;
    let i = 0;
    while (i < valeurs.length) {
      if (valeurs[i].lineThrough && valeurs[i].idxG == idxG) {
        valeurs.splice(i, 1);
        i = 0;
      } else {
        i++;
      }
      for (let k = 0; k < grandeurs.length; k++) {
        newIndex = 0;
        for (let l = 0; l < valeurs.length; l++) {
          if (valeurs[l].idxG == k) {
            valeurs[l].idx = newIndex;
            newIndex++;
          }
        }
      }
    }
    store.set(valeurs);
  }
  // fonction de transfert d'event pour modifier l'onglet actif dans l'objet de App
  function changeActiveG(e) {
    grandeurs = e.detail;
    dispatch4("activeOnglet2", grandeurs);
  }
</script>

{#if grandeurs[idxG].visible}
  <Onglets {grandeurs} on:activeOnglet={changeActiveG} />
  <div
    class="valeurs ml-16 border-gray-400 border-2 rounded-b-md p-2 m-5"
    id={"ongletContent-" + idxG}
    class:activeContent={grandeurs[idxG].active}
  >
    <div class="font-bold bg-blue-200">Valeurs entrées :</div>
    <br />
    <div class="box-vals">
      <ul class="list-inside">
        {#each valeurs as vals}
          {#if vals.mesure && vals.idxG == idxG}
            <li>
              {#if vals.lineThrough}
                <div
                  class="font-semibold cursor-pointer my-2 bg-gray-200 line-through text-red-600 pl-10"
                  id={vals.idx}
                  on:click={() =>
                    selectIdList(vals.idx, vals.lineThrough, vals.idxG)}
                >
                  {vals.mesure}
                </div>
              {/if}
              {#if !vals.lineThrough}
                <div
                  class="font-semibold cursor-pointer my-2 bg-gray-200 w-full pl-10"
                  id={vals.idx}
                  on:click={() =>
                    selectIdList(vals.idx, vals.lineThrough, vals.idxG)}
                >
                  {vals.mesure}
                </div>
              {/if}
            </li>
          {/if}
        {/each}
      </ul>
    </div>
    <div class="text-center p-3">
      <span>Supprimer </span>
      <button
        class="bg-red-700 rounded-md text-white p-0"
        on:click={deleteValues}>&#128473;&#xFE0E;</button
      >
    </div>
  </div>
  <div
    class="incertitudes ml-16 border-gray-400 border-2 rounded-b-md p-2 m-5"
    class:activeResult={grandeurs[idxG].active}
  >
    <CalculsResults {valeurs} {idxG} {incertitudes} {grandeurs} />
  </div>
{/if}

<style>
  .valeurs {
    position: absolute;
    top: 38px;
    left: 201px;
    width: 258px;
    height: 600px;
    display: inline-block;
    text-align: center;
    z-index: 0;
    opacity: 0;
  }
  .box-vals {
    height: 470px;
    overflow: scroll;
    text-align: left;
    padding-left: 5px;
    padding-right: 15px;
    font-weight: bold;
  }
  .activeContent {
    opacity: 1;
    z-index: 1;
  }
  .incertitudes {
    position: absolute;
    top: 38px;
    left: 485px;
    height: 600px;
    width: 282px;
    opacity: 0;
  }
  .activeResult {
    opacity: 1;
  }
</style>
